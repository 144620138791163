import React, { useState } from "react"
import withRouter from "../../components/Common/withRouter"
import { Link } from "react-router-dom"

const GridXiiDiploma = props => {
  const [selectedOption, setSelectedOption] = useState("")

  const handleSelectChange = event => {
    setSelectedOption(event.target.value)
  }

  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const [isChecked, setIsChecked] = useState(false)

  const handleChange = () => {
    setIsChecked(!isChecked)
  }

  return (
    <>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl md:text-3xl text-[#131313] font-semibold uppercase font-exo">
          Grade XII/Diploma
        </h1>
      </div>
      <div className="bg-white w-full rounded-lg p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              Select Education<span className="text-red-700">*</span>
            </label>
            <div className="relative">
              <select
                className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333] appearance-none"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="option">option</option>
              </select>
              <i className="fa-solid fa-chevron-down absolute top-[36%] right-[15px]"></i>
            </div>
          </div>
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              Select Boards<span className="text-red-700">*</span>
            </label>
            <div className="relative">
              <select
                className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="option">option</option>
              </select>
              <i className="fa-solid fa-chevron-down absolute top-[36%] right-[15px]"></i>
            </div>
          </div>
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              School/College Name<span className="text-red-700">*</span>
            </label>
            <input
              type="text"
              placeholder="School/College Name"
              className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
            />
          </div>
          <div>
            <button
              aria-haspopup="dialog"
              aria-expanded={isOpen}
              aria-controls="scroll-inside-body-modal"
              onClick={toggleModal}
              className="bg-[#005cbb] border border-[#005cbb] font-exo rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white uppercase mt-2 md:mt-7"
            >
              Find College
            </button>
          </div>
        </div>
        <h3 className="text-[#2a2a2a] text-xl font-semibold capitalize font-exo my-6">
          Address of School/College<span className="text-red-700">*</span>
        </h3>
        <div className="mt-4">
          <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
            address<span className="text-red-700">*</span>
          </label>
          <textarea
            rows="2"
            placeholder="address"
            className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
          ></textarea>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-4">
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              City<span className="text-red-700">*</span>
            </label>
            <input
              type="text"
              placeholder="City"
              className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
            />
          </div>
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              State<span className="text-red-700">*</span>
            </label>
            <input
              type="text"
              placeholder="State"
              className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
            />
          </div>
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              Postal Code<span className="text-red-700">*</span>
            </label>
            <input
              type="text"
              placeholder="Postal Code"
              className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
            />
          </div>
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              Country<span className="text-red-700">*</span>
            </label>
            <div className="relative">
              <select className="relative z-20 w-full rounded-lg border border-[#777779] bg-transparent py-[10px] px-5 outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default disabled:bg-gray-2  placeholder:text-[#333] appearance-none ">
                <option selected="">select </option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
              <i className="fa-solid fa-angle-down absolute top-[36%] right-[20px]"></i>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-4">
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              School/College Country<span className="text-red-700">*</span>
            </label>
            <div className="relative">
              <select className="relative z-20 w-full rounded-lg border border-[#777779] bg-transparent py-[10px] px-5 outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default disabled:bg-gray-2  placeholder:text-[#333] appearance-none ">
                <option selected="">select </option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
              <i className="fa-solid fa-angle-down absolute top-[36%] right-[20px]"></i>
            </div>
          </div>
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              Email Address of College<span className="text-red-700">*</span>
            </label>
            <input
              type="text"
              placeholder="Email Address of College"
              className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
            />
          </div>
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              URL of College<span className="text-red-700">*</span>
            </label>
            <input
              type="text"
              placeholder="URL of College"
              className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
            />
          </div>
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              Academic Month and Year<span className="text-red-700">*</span>
            </label>
            <input
              type="text"
              placeholder="DD/MM/YYYY"
              className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
            />
          </div>
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              Roll No / Seat No<span className="text-red-700">*</span>
            </label>
            <input
              type="text"
              placeholder="Roll No / Seat No"
              className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
            />
          </div>
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              Language of Examination<span className="text-red-700">*</span>
            </label>
            <div className="relative">
              <select className="relative z-20 w-full rounded-lg border border-[#777779] bg-transparent py-[10px] px-5 outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default disabled:bg-gray-2  placeholder:text-[#333] appearance-none ">
                <option selected="">select </option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
              <i className="fa-solid fa-angle-down absolute top-[36%] right-[20px]"></i>
            </div>
          </div>
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              Subjects<span className="text-red-700">*</span>
            </label>
            <input
              type="text"
              placeholder="Subjects"
              className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
            />
          </div>
          <div>
            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
              CGPA or Grade or Marks in %<span className="text-red-700">*</span>
            </label>
            <input
              type="text"
              placeholder="CGPA or Grade or Marks in %"
              className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
            />
          </div>
        </div>
        <div className="text-end mt-4">
          <button className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white">
            Save
          </button>
        </div>
      </div>
      {/* ----------------------------modal------------------------------- */}

      {isOpen && (
        <div
          id="scroll-inside-body-modal"
          className="fixed inset-0 z-50 overflow-x-hidden overflow-y-auto"
          role="dialog"
          aria-labelledby="scroll-inside-body-modal-label"
          tabIndex="-1"
        >
          <div className="flex items-center justify-center min-h-screen p-4 bg-[#8b8b8b8c]">
            <div className="relative bg-white rounded-lg shadow dark:bg-neutral-800 max-w-full 2xl:max-w-[50%]">
              <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
                <h3 className="font-semibold text-gray-800 text-xl font-exo">
                  Find College
                </h3>
                <button
                  type="button"
                  className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                  aria-label="Close"
                  onClick={toggleModal}
                >
                  <span className="sr-only">Close</span>
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="p-4 overflow-y-auto max-h-96">
                <div className="space-y-4">
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
                      <div>
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                          Search College Name
                          <span className="text-red-700">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Search"
                          className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default  placeholder:text-[#333]"
                        />
                      </div>
                      <div>
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                          Location<span className="text-red-700">*</span>
                        </label>
                        <div className="relative">
                          <select className="relative z-20 w-full rounded-lg border border-[#777779] bg-transparent py-[10px] px-5 outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default disabled:bg-gray-2  placeholder:text-[#333] appearance-none ">
                            <option selected="">select </option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                          </select>
                          <i className="fa-solid fa-angle-down absolute top-[36%] right-[20px]"></i>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap mt-7">
                      <div className="w-full">
                        <div className="max-w-full overflow-x-auto">
                          <table className="w-full table-auto">
                            <thead className="bg-[#b9d0ff40] border-b">
                              <tr>
                                <th className="py-4 px-3 text-lg font-medium text-dark text-start">
                                  #
                                </th>
                                <th className="py-4 px-3 text-lg font-medium text-dark text-start">
                                  Name
                                </th>
                                <th className="py-4 px-3 text-lg font-medium text-dark text-start">
                                  Location
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="border-b hover:bg-[#f3f4f6]">
                                <td className="text-[#000000de]  py-4 px-3 text-base font-medium">
                                  <label
                                    htmlFor="checkboxLabelTwo"
                                    className="flex items-center cursor-pointer select-none text-dark dark:text-white"
                                  >
                                    <div className="relative">
                                      <input
                                        type="checkbox"
                                        id="checkboxLabelTwo"
                                        className="sr-only"
                                        checked={isChecked}
                                        onChange={handleChange}
                                      />
                                      <div
                                        className={`flex items-center justify-center w-5 h-5 mr-4 border border-stroke dark:border-dark-3 rounded box ${
                                          isChecked ? "bg-blue-500" : "bg-white"
                                        }`}
                                      >
                                        {isChecked && (
                                          <span>
                                            <svg
                                              width="11"
                                              height="8"
                                              viewBox="0 0 11 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                fill="#fff"
                                                stroke="#fff"
                                                strokeWidth="0.4"
                                              />
                                            </svg>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </label>
                                </td>
                                <td className="text-[#000000de] py-4 px-3 text-base font-medium">
                                  alok vidyashram
                                </td>
                                <td className="text-[#000000de] py-4 px-3 text-base font-medium">
                                  anand bhairav marg
                                </td>
                              </tr>
                              <tr className="border-b hover:bg-[#f3f4f6]">
                                <td className="text-[#000000de]  py-4 px-3 text-base font-medium">
                                  <label
                                    htmlFor="checkboxLabelTwo"
                                    className="flex items-center cursor-pointer select-none text-dark dark:text-white"
                                  >
                                    <div className="relative">
                                      <input
                                        type="checkbox"
                                        id="checkboxLabelTwo"
                                        className="sr-only"
                                        checked={isChecked}
                                        onChange={handleChange}
                                      />
                                      <div
                                        className={`flex items-center justify-center w-5 h-5 mr-4 border border-stroke dark:border-dark-3 rounded box ${
                                          isChecked ? "bg-blue-500" : "bg-white"
                                        }`}
                                      >
                                        {isChecked && (
                                          <span>
                                            <svg
                                              width="11"
                                              height="8"
                                              viewBox="0 0 11 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                fill="#fff"
                                                stroke="#fff"
                                                strokeWidth="0.4"
                                              />
                                            </svg>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </label>
                                </td>
                                <td className="text-[#000000de] py-4 px-3 text-base font-medium">
                                  modern indian school
                                </td>
                                <td className="text-[#000000de] py-4 px-3 text-base font-medium">
                                  Chobhar, Kathmandu, Nepal
                                </td>
                              </tr>
                              <tr className="border-b hover:bg-[#f3f4f6]">
                                <td className="text-[#000000de]  py-4 px-3 text-base font-medium">
                                  <label
                                    htmlFor="checkboxLabelTwo"
                                    className="flex items-center cursor-pointer select-none text-dark dark:text-white"
                                  >
                                    <div className="relative">
                                      <input
                                        type="checkbox"
                                        id="checkboxLabelTwo"
                                        className="sr-only"
                                        checked={isChecked}
                                        onChange={handleChange}
                                      />
                                      <div
                                        className={`flex items-center justify-center w-5 h-5 mr-4 border border-stroke dark:border-dark-3 rounded box ${
                                          isChecked ? "bg-blue-500" : "bg-white"
                                        }`}
                                      >
                                        {isChecked && (
                                          <span>
                                            <svg
                                              width="11"
                                              height="8"
                                              viewBox="0 0 11 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                fill="#fff"
                                                stroke="#fff"
                                                strokeWidth="0.4"
                                              />
                                            </svg>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </label>
                                </td>
                                <td className="text-[#000000de] py-4 px-3 text-base font-medium">
                                  faips kuwait
                                </td>
                                <td className="text-[#000000de] py-4 px-3 text-base font-medium">
                                  Al-Ahmadi Kuwait
                                </td>
                              </tr>
                              <tr className="border-b hover:bg-[#f3f4f6]">
                                <td className="text-[#000000de]  py-4 px-3 text-base font-medium">
                                  <label
                                    htmlFor="checkboxLabelTwo"
                                    className="flex items-center cursor-pointer select-none text-dark dark:text-white"
                                  >
                                    <div className="relative">
                                      <input
                                        type="checkbox"
                                        id="checkboxLabelTwo"
                                        className="sr-only"
                                        checked={isChecked}
                                        onChange={handleChange}
                                      />
                                      <div
                                        className={`flex items-center justify-center w-5 h-5 mr-4 border border-stroke dark:border-dark-3 rounded box ${
                                          isChecked ? "bg-blue-500" : "bg-white"
                                        }`}
                                      >
                                        {isChecked && (
                                          <span>
                                            <svg
                                              width="11"
                                              height="8"
                                              viewBox="0 0 11 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                fill="#fff"
                                                stroke="#fff"
                                                strokeWidth="0.4"
                                              />
                                            </svg>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </label>
                                </td>
                                <td className="text-[#000000de] py-4 px-3 text-base font-medium">
                                  indian school al wadi al kabir
                                </td>
                                <td className="text-[#000000de] py-4 px-3 text-base font-medium">
                                  PO BOX 513 ,PC 117, AL WADI AL KABIR
                                </td>
                              </tr>
                              <tr className="border-b hover:bg-[#f3f4f6]">
                                <td className="text-[#000000de]  py-4 px-3 text-base font-medium">
                                  <label
                                    htmlFor="checkboxLabelTwo"
                                    className="flex items-center cursor-pointer select-none text-dark dark:text-white"
                                  >
                                    <div className="relative">
                                      <input
                                        type="checkbox"
                                        id="checkboxLabelTwo"
                                        className="sr-only"
                                        checked={isChecked}
                                        onChange={handleChange}
                                      />
                                      <div
                                        className={`flex items-center justify-center w-5 h-5 mr-4 border border-stroke dark:border-dark-3 rounded box ${
                                          isChecked ? "bg-blue-500" : "bg-white"
                                        }`}
                                      >
                                        {isChecked && (
                                          <span>
                                            <svg
                                              width="11"
                                              height="8"
                                              viewBox="0 0 11 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                fill="#fff"
                                                stroke="#fff"
                                                strokeWidth="0.4"
                                              />
                                            </svg>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </label>
                                </td>
                                <td className="text-[#000000de] py-4 px-3 text-base font-medium">
                                  lycée bellepierre
                                </td>
                                <td className="text-[#000000de] py-4 px-3 text-base font-medium">
                                  BD Gaston Monerville 97400 saint denis
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
                <button
                  type="button"
                  className="bg-[#005cbb] border border-[#005cbb]0 font-exo rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white uppercase"
                  onClick={toggleModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="bg-[#005cbb] border border-[#005cbb]0 font-exo rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white uppercase"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default withRouter(GridXiiDiploma)
