import React, { useState } from "react";
import withRouter from "../../components/Common/withRouter";
import banner from "../../assets/images/banner.webp"
import { Link } from "react-router-dom"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import slide1 from "../../assets/images/slider1.png"
import slide2 from "../../assets/images/slider2.png"
import slide3 from "../../assets/images/slider3.png"
import logo from "../../assets/images/logo.png"
import logo2 from "../../assets/images/vidyapeeth_new.png"
const Home = (props) => {


    return (
        <>
            <div className="container-fluid m-auto">
                <div className="bg-white">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="hidden md:block">
                            <Swiper
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, Pagination]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <div className="relative">
                                        <img src={slide1} alt="img" className="w-full h-[960px] object-cover" />
                                        <div className="absolute left-0 top-0 p-12">
                                            <h2 className="text-3xl font-exo font-semibold max-w-[755px]">Apply to more than 500+
                                                courses at the university</h2>
                                            <p className="text-base font-medium mt-2 max-w-[755px]">Study on campus or any of 750+ affiliate colleges of university.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="relative">
                                        <img src={slide2} alt="img" className="w-full h-[960px] object-cover" />
                                        <div className="absolute left-0 top-0 p-12">
                                            <h2 className="text-3xl font-exo font-semibold max-w-[755px]">Study at one of the oldest universities in India</h2>
                                            <p className="text-base font-medium mt-2 max-w-[755px]">University with 5 star status in 2001 & 'A' Grade status in 2012.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="relative">
                                        <img src={slide3} alt="img" className="w-full h-[960px] object-cover" />
                                        <div className="absolute left-0 top-0 p-12">
                                            <h2 className="text-3xl font-exo font-semibold max-w-[755px]">Study with students from
                                                more than 35+ countries</h2>
                                            <p className="text-base font-medium mt-2 max-w-[755px]">Study with students from more than 35+ countries, fostering a diverse and enriching learning environment that broadens your perspectives.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <div className="bg-white flex items-center justify-center px-6 md:px-20 py-20 h-screen md:h-auto">
                            <div className="max-w-[410px] m-auto">
                                <div className="text-center mb-10">
                                    <img src={logo} alt="logo" className="inline-block mr-2" />
                                    <img src={logo2} alt="logo" className="inline-block" />
                                </div>
                                <h2 className="text-4xl font-bold uppercase text-[#005cbb]">REGISTER WITH US</h2>
                                <h3 className="text-lg font-bold uppercase text-[#000] mt-2">International Students(NRI/OCI/PIO/Foreign National)</h3>
                                <p className="text-md text-[#333] font-normal mt-3">Gift yourself a promising career</p>
                                <div className="flex items-center gap-5 mt-7">
                                    <Link to="/registartion" className="bg-[#005cbb] text-base text-white font-semibold rounded-lg uppercase border-b-3 border-pink px-5 py-4 w-full text-center block">Register</Link>
                                    <Link to="/login" className="bg-[#005cbb] text-base text-white font-semibold rounded-lg uppercase border-b-3 border-pink px-5 py-4 w-full text-center block">Login</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default withRouter(Home);
