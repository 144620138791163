import PropTypes from "prop-types"
import React from "react"
import withRouter from "../../components/Common/withRouter"
import { useLocation } from "react-router-dom"
// Layout Related Components

const Layout = props => {
  return <React.Fragment>{props.children}</React.Fragment>
}

Layout.propTypes = {
  children: PropTypes.any,
}

export default withRouter(Layout)
