import React from "react"
import { Navigate } from "react-router-dom"
import Profile from "../pages/Profile/Index"
import Registration from "../pages/Registration/Index"
import Login from "../pages/Login/Index"
import Home from "../pages/Home/Index"
import GridX from "../pages/GridX/Edit"
import GridXI from "../pages/GridXI/Edit"
import GridXiiDiploma from "../pages/GridXiiDiploma/Edit"
import Degree from "../pages/Degree/Edit"
const authProtectedRoutes = [
 

  { path: "/profile", component: <Profile /> },
  { path: "/grid-x-edit", component: <GridX /> },
  { path: "/grid-xi-edit", component: <GridXI /> },
  { path: "/grid-xii-diploma", component: <GridXiiDiploma /> },
  { path: "/degree", component: <Degree /> },


]
const authProtectedRoutesWithNonAuthLayout = [
 
]
const nonAuthLayout = [
  { path: "/registartion", component: <Registration/> },
  { path: "/login", component: <Login/> },
  { path: "/home", component: <Home/> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/home" />,
  },
]
export {
  authProtectedRoutes,
  nonAuthLayout,
  authProtectedRoutesWithNonAuthLayout,
}
