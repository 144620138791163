import React, { useState } from "react"
import { useEffect, useRef } from "react"
import logo from "../../assets/images/logo.png"
import logo2 from "../../assets/images/vidyapeeth_new.png"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
const Header = ({ sidebarToggle, setSidebarToggle }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className="relative">
        <div className="fixed top-0 z-20 flex w-full bg-[#FAFAFA] drop-shadow">
          <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11 h-[95px]">
            <div className="flex items-center gap-8">
              <div
                className="cursor-pointer"
                onClick={() => setSidebarToggle(!sidebarToggle)}
              >
                <i className="fas fa-bars text-[#242424] text-2xl"></i>
              </div>
              <img src={logo} alt="logo" className="inline-block h-[62px]" />
              <img src={logo2} alt="logo2" className="hidden md:inline-block" />
            </div>
            <div className="flex items-center gap-5">
              <Link to="">
                <div className="flex items-center justify-center w-[44px] h-[44px] bg-[#f0f3fb] rounded-full text-[#333]">
                  <i className="fa-solid fa-bell"></i>
                </div>
              </Link>
              <Link to="/profile">
                <p>jh</p>
              </Link>
              <div
                className="relative inline-block text-left"
                ref={dropdownRef}
              >
                <div>
                  <button
                    type="button"
                    id="menu-button"
                    aria-expanded={isOpen}
                    aria-haspopup="true"
                    onClick={toggleDropdown}
                  >
                    <div className="py-[9px] px-[6px] text-[#333]">
                      <i className="fas fa-caret-down"></i>
                    </div>
                  </button>
                </div>

                {isOpen && (
                  <div
                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                  >
                    <div className="py-1" role="none">
                      <Link
                        to="" // Specify your logout link destination
                        className="text-black text-base block px-4 py-2 font-body font-medium hover:bg-primary hover:text-dark"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-0"
                      >
                        Log Out
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Header.propTypes = {
  sidebarToggle: PropTypes.bool,
  setSidebarToggle: PropTypes.func,
}

export default Header
