import React, { useState } from "react";
import withRouter from "../../components/Common/withRouter";
import { Link } from "react-router-dom"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import img from "../../assets/images/bg-slide.png"
import slide1 from "../../assets/images/slider1.png"
import slide2 from "../../assets/images/slider2.png"
import slide3 from "../../assets/images/slider3.png"
import logo from "../../assets/images/logo.png"
import logo2 from "../../assets/images/vidyapeeth_new.png"

const Login = (props) => {
    const [isCheckedBox, setIsCheckedBox] = useState(false);

    const handleBoxChange = () => {
        setIsCheckedBox(!isCheckedBox);
    };

    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="container-fluid m-auto">
                <div className="bg-white">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="hidden md:block">
                            <Swiper
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, Pagination]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <div className="relative">
                                        <img src={slide1} alt="img" className="w-full h-[960px] object-cover" />
                                        <div className="absolute left-0 top-0 p-12">
                                            <h2 className="text-3xl font-exo font-semibold max-w-[755px]">Apply to more than 500+
                                                courses at the university</h2>
                                            <p className="text-base font-medium mt-2 max-w-[755px]">Study on campus or any of 750+ affiliate colleges of university.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="relative">
                                        <img src={slide2} alt="img" className="w-full h-[960px] object-cover" />
                                        <div className="absolute left-0 top-0 p-12">
                                            <h2 className="text-3xl font-exo font-semibold max-w-[755px]">Study at one of the oldest universities in India</h2>
                                            <p className="text-base font-medium mt-2 max-w-[755px]">University with 5 star status in 2001 & 'A' Grade status in 2012.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="relative">
                                        <img src={slide3} alt="img" className="w-full h-[960px] object-cover" />
                                        <div className="absolute left-0 top-0 p-12">
                                            <h2 className="text-3xl font-exo font-semibold max-w-[755px]">Study with students from
                                                more than 35+ countries</h2>
                                            <p className="text-base font-medium mt-2 max-w-[755px]">Study with students from more than 35+ countries, fostering a diverse and enriching learning environment that broadens your perspectives.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <div className="bg-white flex items-center justify-center px-6 md:px-20 py-20 min-h-screen md:h-auto">
                            <div className="max-w-[390px] m-auto">
                                <div className="text-center mb-10">
                                    <img src={logo} alt="logo" className="inline-block mr-2" />
                                    <img src={logo2} alt="logo" className="inline-block" />
                                </div>
                                <div className="text-center">
                                    <h2 className="text-3xl text-[#2a2a2a] font-medium font-exo">Login</h2>
                                    <p className="text-base text-[#4b4b4b] font-medium font-exo mt-4">Welcome to University Of Mumbai! Log in with your email.</p>
                                </div>
                                <div className="mb-4 mt-10">
                                    <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                                        Email address:<span className="text-red-700">*</span>
                                    </label>
                                    <input type="text" placeholder="email" className="w-full bg-transparent rounded-md border border-dark py-[10px] px-5 text-dark-6 outline-none transition focus:border-dark active:border-dark disabled:cursor-default  placeholder:text-[#4b4b4b]" />
                                </div>
                                <div className="mb-4">
                                    <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                                        Password:<span className="text-red-700">*</span>
                                    </label>
                                    <div className="relative">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Password"
                                            className="w-full bg-transparent rounded-md border border-dark py-[10px] px-5 text-dark-6 outline-none transition focus:border-dark active:border-dark disabled:cursor-default placeholder:text-[#4b4b4b]"
                                        />
                                        <div
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {showPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between mt-14">
                                    <div>
                                        <label
                                            htmlFor="checkboxLabelTwo"
                                            className="flex items-center text-base font-meduim cursor-pointer select-none text-dark "
                                        >
                                            <div className="relative">
                                                <input
                                                    type="checkbox"
                                                    id="checkboxLabelTwo"
                                                    className="sr-only"
                                                    checked={isCheckedBox}
                                                    onChange={handleBoxChange}
                                                />
                                                <div
                                                    className={`flex items-center justify-center w-5 h-5 mr-2 border border-pink rounded box ${isCheckedBox ? 'bg-white' : 'bg-white'
                                                        }`}
                                                >
                                                    {isCheckedBox && (
                                                        <span>
                                                            <svg
                                                                width="11"
                                                                height="8"
                                                                viewBox="0 0 11 8"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                                    fill="#000"
                                                                    stroke="#000"
                                                                    strokeWidth="0.4"
                                                                />
                                                            </svg>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            Remember me
                                        </label>
                                    </div>
                                    <Link to="" className="text-[#005cbb]">Forgot Password?
                                    </Link>
                                </div>
                                <Link to="/profile" className="bg-white  text-lg font-exo font-medium w-full px-3 py-2 rounded-lg mt-8 block text-center border border-[#005cbb] text-[#005cbb] hover:bg-[#146ac1] hover:border-[#146ac1] hover:text-white"> 
                                    Login
                                </Link>
                                <p className="text-base text-[#4b4b4b] text-center mt-8">Don't have an account?<Link to="/registartion" className="text-[#a4abb3] hover:underline">Register</Link></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default withRouter(Login);
