import PropTypes from "prop-types"
import React, { useState } from "react"
import withRouter from "../../components/Common/withRouter"


// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"

const Layout = props => {
 

  const [sidebarToggle, setSidebarToggle] = useState(true)
  return (
    <React.Fragment>
      <Header
        sidebarToggle={sidebarToggle}
        setSidebarToggle={setSidebarToggle}
      />
      <div className="flex">
        <Sidebar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />

        <div className="relative flex flex-1 flex-col">
          <main className={`${sidebarToggle ? "xl:ml-[252px]" : "ml-[0px]"}`}>
            <div className="px-4 pt-[130px] pb-8 shadow-2 md:px-6 2xl:px-11">
              {/* {loading && (
                <div className="fixed inset-0 z-50 bg-[#FAFAFA] bg-opacity-80 flex items-center justify-center">
                  <div
                    className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span
                      className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >
                      Loading...
                    </span>
                  </div>
                </div>
              )} */}
              {props.children}
            </div>
          </main>
        </div>
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
}

export default withRouter(Layout)
