import React, { useState } from "react"
import withRouter from "../../components/Common/withRouter"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import canadaImg from "../../assets/images/Canada.png"
import chinaImg from "../../assets/images/China.png"
import ghanaImg from "../../assets/images/Ghana.png"
import iranImg from "../../assets/images/Iran.png"
import iraqImg from "../../assets/images/Iraq.png"
import nigeriaImg from "../../assets/images/Nigeria.png"
import thailandImg from "../../assets/images/Thailand.png"
import play from "../../assets/images/PLAY.png"
import campus from "../../assets/images/campus.webp"
import { Link } from "react-router-dom"
const Profile = props => {
  const [startDate, setStartDate] = useState(new Date())
  const [currentStep, setCurrentStep] = useState(1)
  const [completedSteps, setCompletedSteps] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)

  const nextStep = () => {
    if (currentStep < 6) {
      setCompletedSteps([...completedSteps, currentStep]) // Mark current step as completed
      setCurrentStep(currentStep + 1)
    }
  }

  const prevStep = () => {
    if (currentStep > 1) {
      setCompletedSteps(completedSteps.filter(step => step !== currentStep - 1)) // Remove from completed steps if going back
      setCurrentStep(currentStep - 1)
    }
  }

  const [borderColor, setBorderColor] = useState("#acacac")
  const handleClick = () => {
    setBorderColor(borderColor === "#acacac" ? "#9a91ff" : "#acacac")
  }

  const handleCategoryClick = category => {
    setSelectedCategory(category)
  }
  const [isChecked, setIsChecked] = useState(false)

  const handleChange = () => {
    setIsChecked(!isChecked)
  }

  const [selectedOption, setSelectedOption] = useState("Grade X")

  const handleSelectChange = event => {
    setSelectedOption(event.target.value)
  }

  const steps = [
    { id: 1, label: "Current Location" },
    { id: 2, label: "Additional Information" },
    { id: 3, label: "Guardian Information" },
    { id: 4, label: "Educational Information" },
    { id: 5, label: "Sports Information" },
    { id: 6, label: "Transcript Information" },
  ]

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="p-4 border-b border-[#dddddd]">
              <h2 className="text-[#333] text-xl md:text-2xl font-semibold capitalize font-exo">
                Select location from where you completed 12th board
              </h2>
            </div>
            <div className="px-4 py-8">
              <div className="grid grid-cols-2 gap-7">
                <div>
                  <label className="mb-[10px] block text-base font-medium text-[#4b4b4b]">
                    Select location from where you completed 12th board
                  </label>
                  <input
                    type="text"
                    placeholder="location"
                    className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                  />
                </div>
                <div>
                  <label className="mb-[10px] block text-base font-medium text-[#4b4b4b]">
                    Select location from where the Passport is issued
                  </label>
                  <input
                    type="text"
                    placeholder="Search location"
                    className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                  />
                </div>
              </div>
              <h2 className="text-[#333] font-exo font-semibold text-lg mt-7">Select Country</h2>
              <div className="grid grid-cols-4 gap-7 mt-5">
                {[
                  {
                    name: "Canada",
                    imgSrc: canadaImg,
                  },
                  { name: "China", imgSrc: chinaImg },
                  {
                    name: "Ghana",
                    imgSrc: ghanaImg,
                  },
                  { name: "Iran", imgSrc: iranImg },
                  { name: "iraq", imgSrc: iraqImg },
                  { name: "Nigeria", imgSrc: nigeriaImg },
                  { name: "Thailand", imgSrc: thailandImg },
                ].map(({ name, imgSrc }) => (
                  <div
                    className="relative group"
                    key={name}
                    onClick={() => handleCategoryClick(name)}
                  >
                    <div
                      className={`text-center border group-hover:border-[#005cbb] ${selectedCategory === name ? "border-[#005cbb]" : "border-[#777779]"} 
            rounded-md cursor-pointer ease-in-out duration-700 p-3`}
                    >
                      <div className="flex items-center gap-3">
                        <div className="flex-1">
                          <div className="flex items-center">
                            <img src={imgSrc} alt={name} className="h-8 w-8" />
                            <p
                              className={`ml-3 font-exo ${
                                selectedCategory === name
                                  ? "text-[#005cbb]"
                                  : "text-[#333]"
                              }`}
                            >
                              {name}
                            </p>
                          </div>
                        </div>
                        <div className="flex-none">
                          {selectedCategory === name && (
                            <div className="bg-[#005cbb] text-white h-[26px] w-[26px] flex items-center justify-center rounded-full p-2">
                              <i className="fas fa-check text-base"></i>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )
      case 2:
        return (
          <>
            <div>
              <div className="p-4 border-b border-[#dddddd]">
                <h2 className="text-[#333] text-xl md:text-2xl font-semibold capitalize font-exo">
                  Additional Information
                </h2>
              </div>
              <div className="px-4 py-8">
                <div className="grid grid-cols-1">
                  <div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                      <div>
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b]">
                          Citizenship<span className="text-red-700">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="IRAK"
                          className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                        />
                      </div>
                      <div>
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b]">
                          Date of Birth<span className="text-red-700">*</span>
                        </label>
                        <DatePicker
                          showIcon
                          selected={startDate}
                          onChange={date => setStartDate(date)}
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              viewBox="0 0 48 48"
                            >
                              <mask id="ipSApplication0">
                                <g
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinejoin="round"
                                  strokeWidth="4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    d="M40.04 22v20h-32V22"
                                  ></path>
                                  <path
                                    fill="#fff"
                                    d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                                  ></path>
                                </g>
                              </mask>
                              <path
                                fill="currentColor"
                                d="M0 0h48v48H0z"
                                mask="url(#ipSApplication0)"
                              ></path>
                            </svg>
                          }
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 mt-4 mb-4">
                      <div>
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b]">
                          Permanent Address
                          <span className="text-red-700">*</span>
                        </label>
                        <textarea
                          rows="2"
                          placeholder="Permanent Address"
                          className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                        ></textarea>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                      <div>
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b]">
                          City<span className="text-red-700">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="City"
                          className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                        />
                      </div>
                      <div>
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b]">
                          State<span className="text-red-700">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="State"
                          className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                        />
                      </div>
                      <div>
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b]">
                          Postal Code<span className="text-red-700">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Postal Code"
                          className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                        />
                      </div>
                      <div>
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b]">
                          Country<span className="text-red-700">*</span>
                        </label>
                        <div className="relative">
                          <select className="relative z-20 w-full rounded-lg border border-[#777779] bg-transparent py-[10px] px-5 outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default disabled:bg-gray-2 placeholder:text-[#4b4b4b] appearance-none">
                            <option selected="">Select</option>
                            <option>Canada</option>
                            <option>China</option>
                            <option>Ghana</option>
                            <option>Iran</option>
                          </select>
                          <i className="fa-solid fa-angle-down absolute top-[36%] right-[20px]"></i>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <label
                        htmlFor="checkboxLabelTwo"
                        className="flex items-center cursor-pointer select-none text-dark dark:text-white"
                      >
                        <div className="relative">
                          <input
                            type="checkbox"
                            id="checkboxLabelTwo"
                            className="sr-only"
                            checked={isChecked}
                            onChange={handleChange}
                          />
                          <div
                            className={`flex items-center justify-center w-5 h-5 mr-4 border border-[#777779] dark:border-dark-3 rounded box ${
                              isChecked ? "bg-blue-500" : "bg-white"
                            }`}
                          >
                            {isChecked && (
                              <span>
                                <svg
                                  width="11"
                                  height="8"
                                  viewBox="0 0 11 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                    fill="#fff"
                                    stroke="#fff"
                                    strokeWidth="0.4"
                                  />
                                </svg>
                              </span>
                            )}
                          </div>
                        </div>
                        Add other address?
                      </label>
                      {isChecked && (
                        <>
                          <div className="mt-4">
                            <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                              Alternate Address(Optional)
                              <span className="text-red-700">*</span>
                            </label>
                            <textarea
                              rows="2"
                              placeholder="Alternate Address"
                              className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                            ></textarea>
                          </div>
                          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-4">
                            <div>
                              <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                                City<span className="text-red-700">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="City"
                                className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                              />
                            </div>
                            <div>
                              <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                                State<span className="text-red-700">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="State"
                                className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                              />
                            </div>
                            <div>
                              <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                                Postal Code
                                <span className="text-red-700">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Postal Code"
                                className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                              />
                            </div>
                            <div>
                              <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                                Country<span className="text-red-700">*</span>
                              </label>
                              <div className="relative">
                                <select className="relative z-20 w-full rounded-lg border border-[#777779] bg-transparent py-[10px] px-5 outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default disabled:bg-gray-2  placeholder:text-[#333] appearance-none ">
                                  <option selected="">select </option>
                                  <option>Canada</option>
                                  <option>China</option>
                                  <option>Ghana</option>
                                  <option>Iran</option>
                                </select>
                                <i className="fa-solid fa-angle-down absolute top-[36%] right-[20px]"></i>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <h2 className="text-3xl text-[#2a2a2a] font-medium my-4 font-exo">
                      Passport details
                    </h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                      <div>
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                          Passport Number<span className="text-red-700">*</span>
                        </label>
                        <input
                          type="number"
                          placeholder="Passport Number"
                          className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                        />
                      </div>
                      <div>
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                          Passport issuing Country
                          <span className="text-red-700">*</span>
                        </label>
                        <div className="relative">
                          <select className="relative z-20 w-full rounded-lg border border-[#777779] bg-transparent py-[10px] px-5 outline-none transition focus:border-[#005cbb] active:border-[#005cbb]disabled:cursor-default disabled:bg-gray-2  placeholder:text-[#4b4b4b] appearance-none ">
                            <option selected="">
                              Passport issuing Country{" "}
                            </option>
                            <option>Canada</option>
                            <option>China</option>
                            <option>Ghana</option>
                            <option>Iran</option>
                          </select>
                          <i className="fa-solid fa-angle-down absolute top-[36%] right-[20px]"></i>
                        </div>
                      </div>
                      <div>
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                          Passport - Date of Issue
                          <span className="text-red-700">*</span>
                        </label>
                        <DatePicker
                          showIcon
                          selected={startDate}
                          onChange={date => setStartDate(date)}
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              viewBox="0 0 48 48"
                            >
                              <mask id="ipSApplication0">
                                <g
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinejoin="round"
                                  strokeWidth="4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    d="M40.04 22v20h-32V22"
                                  ></path>
                                  <path
                                    fill="#fff"
                                    d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                                  ></path>
                                </g>
                              </mask>
                              <path
                                fill="currentColor"
                                d="M0 0h48v48H0z"
                                mask="url(#ipSApplication0)"
                              ></path>
                            </svg>
                          }
                        />
                      </div>
                      <div>
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                          Passport - Date of Expiry
                          <span className="text-red-700">*</span>
                        </label>
                        <DatePicker
                          showIcon
                          selected={startDate}
                          onChange={date => setStartDate(date)}
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              viewBox="0 0 48 48"
                            >
                              <mask id="ipSApplication0">
                                <g
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinejoin="round"
                                  strokeWidth="4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    d="M40.04 22v20h-32V22"
                                  ></path>
                                  <path
                                    fill="#fff"
                                    d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                                  ></path>
                                </g>
                              </mask>
                              <path
                                fill="currentColor"
                                d="M0 0h48v48H0z"
                                mask="url(#ipSApplication0)"
                              ></path>
                            </svg>
                          }
                        />
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </>
        )
      case 3:
        return (
          <>
            <div>
              <div className="p-4 border-b border-[#dddddd]">
                <h2 className="text-[#333] text-xl md:text-2xl font-semibold capitalize font-exo">
                  Guardian Information
                </h2>
              </div>
              <div className="px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div>
                    <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                      Relation<span className="text-red-700">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Relation"
                      className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                    />
                  </div>
                  <div>
                    <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                      Full Name<span className="text-red-700">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Full Name"
                      className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                    />
                  </div>
                  <div>
                    <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                      Guardian Email Address
                      <span className="text-red-700">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Guardian Email Address"
                      className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                    />
                  </div>
                  <div>
                    <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                      City<span className="text-red-700">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="City"
                      className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                    />
                  </div>
                  <div>
                    <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                      Indian Mobile Number
                    </label>
                    <input
                      type="number"
                      placeholder="Indian Mobile Number"
                      className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                    />
                  </div>
                  <div>
                    <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                      Mobile Number<span className="text-red-700">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Mobile Number"
                      className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                    />
                  </div>
                  <div>
                    <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                      Guardian WhatsApp Mobile Number
                    </label>
                    <input
                      type="number"
                      placeholder="Guardian WhatsApp Mobile Number"
                      className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                    />
                  </div>
                  <div>
                    <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                      Permanent Address<span className="text-red-700">*</span>
                    </label>
                    <textarea
                      rows="2"
                      placeholder="Alternate Address"
                      className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                    ></textarea>
                  </div>
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="checkboxLabelTwo"
                    className="flex items-center cursor-pointer select-none text-dark dark:text-white"
                  >
                    <div className="relative">
                      <input
                        type="checkbox"
                        id="checkboxLabelTwo"
                        className="sr-only"
                        checked={isChecked}
                        onChange={handleChange}
                      />
                      <div
                        className={`flex items-center justify-center w-5 h-5 mr-4 border border-stroke dark:border-dark-3 rounded box ${
                          isChecked ? "bg-blue-500" : "bg-white"
                        }`}
                      >
                        {isChecked && (
                          <span>
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 11 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                fill="#fff"
                                stroke="#fff"
                                strokeWidth="0.4"
                              />
                            </svg>
                          </span>
                        )}
                      </div>
                    </div>
                    Add other address?
                  </label>
                  {isChecked && (
                    <>
                      <div className="mt-4">
                        <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                          Alternate Address(Optional)
                          <span className="text-red-700">*</span>
                        </label>
                        <textarea
                          rows="2"
                          placeholder="Alternate Address"
                          className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                        ></textarea>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-4">
                        <div>
                          <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                            City<span className="text-red-700">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="City"
                            className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                          />
                        </div>
                        <div>
                          <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                            State<span className="text-red-700">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="State"
                            className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                          />
                        </div>
                        <div>
                          <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                            Postal Code<span className="text-red-700">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Postal Code"
                            className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                          />
                        </div>
                        <div>
                          <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                            Country<span className="text-red-700">*</span>
                          </label>
                          <div className="relative">
                            <select className="relative z-20 w-full rounded-lg border border-[#777779] bg-transparent py-[10px] px-5 outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default disabled:bg-gray-2  placeholder:text-[#333] appearance-none ">
                              <option selected="">select </option>
                              <option>Canada</option>
                              <option>China</option>
                              <option>Ghana</option>
                              <option>Iran</option>
                            </select>
                            <i className="fa-solid fa-angle-down absolute top-[36%] right-[20px]"></i>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )
      case 4:
        return (
          <>
            <div>
              <div className="p-4 border-b border-[#dddddd]">
                <h2 className="text-[#333] text-xl md:text-2xl font-semibold capitalize font-exo">
                  Educational Information
                </h2>
              </div>
              <div className="px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
                  {/* ---------------grade x------------------ */}
                  <div className="bg-white rounded-xl border">
                    <div className="flex items-center justify-between bg-[#005cbb] p-4 border-b rounded-t-xl ">
                      <h2 className="text-lg 2xl:text-2xl text-white font-exo font-semibold flex-1">
                        Grade X
                      </h2>
                      <div className="flex-none">
                        <Link to="/grid-x-edit">
                          <i className="fa-solid fa-pen inline-block mr-3 text-white"></i>
                        </Link>
                        <Link to="">
                          <i className="fa-solid fa-trash inline-block text-white"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline">
                          Qualification:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          CBSE/ICSE/SSC Marks
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          Board / University:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          National Board of Secondary Education of Royal Govt.
                          of Bhutan
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          Faculty/College Name:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          Royal Govt. of Bhutan
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          Passing Year-Month:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          2022/03
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          CGPA / Marks:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          80%
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* ---------------grade xi------------------ */}
                  <div className="bg-white rounded-xl border">
                    <div className="flex items-center justify-between bg-[#005cbb] p-4 border-b rounded-t-xl">
                      <h2 className="text-lg 2xl:text-2xl text-white font-exo font-semibold flex-1">
                        Grade XI
                      </h2>
                      <div className="flex-none">
                        <Link to="/grid-xi-edit">
                          <i className="fa-solid fa-pen inline-block mr-3 text-white"></i>
                        </Link>
                        <Link to="">
                          <i className="fa-solid fa-trash inline-block text-white"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline">
                          Qualification:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          CBSE/ICSE/SSC Marks
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          Board / University:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          National Board of Secondary Education of Royal Govt.
                          of Bhutan
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          Faculty/College Name:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          Royal Govt. of Bhutan
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          Passing Year-Month:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          2022/03
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          CGPA / Marks:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          80%
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* ---------------Grade XII/Diploma------------------ */}
                  <div className="bg-white rounded-xl border">
                    <div className="flex items-center justify-between bg-[#005cbb] p-4 border-b rounded-t-xl">
                      <h2 className="text-lg 2xl:text-2xl text-white font-exo font-semibold flex-1">
                        Grade XII/Diploma
                      </h2>
                      <div className="flex-none">
                        <Link to="/grid-xii-diploma">
                          <i className="fa-solid fa-pen inline-block mr-3 text-white"></i>
                        </Link>
                        <Link to="">
                          <i className="fa-solid fa-trash inline-block text-white"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline">
                          Qualification:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          CBSE/ICSE/SSC Marks
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          Board / University:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          National Board of Secondary Education of Royal Govt.
                          of Bhutan
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          Faculty/College Name:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          Royal Govt. of Bhutan
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          Passing Year-Month:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          2022/03
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          CGPA / Marks:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          80%
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* ---------------Degree------------------ */}
                  <div className="bg-white rounded-xl border">
                    <div className="flex items-center justify-between bg-[#005cbb] p-4 border-b rounded-t-xl">
                      <h2 className="text-lg 2xl:text-2xl text-white font-exo font-semibold flex-1">
                        Degree
                      </h2>
                      <div className="flex-none">
                        <Link to="/degree">
                          <i className="fa-solid fa-pen inline-block mr-3 text-white"></i>
                        </Link>
                        <Link to="">
                          <i className="fa-solid fa-trash inline-block text-white"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline">
                          Qualification:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          CBSE/ICSE/SSC Marks
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          Board / University:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          National Board of Secondary Education of Royal Govt.
                          of Bhutan
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          Faculty/College Name:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          Royal Govt. of Bhutan
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          Passing Year-Month:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          2022/03
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-lg font-semibold underline text-nowrap">
                          CGPA / Marks:
                        </p>
                        <p className="text-sm text-[#4b4b4b] font-meduim mt-1">
                          80%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      case 5:
        return (
          <div>
            <div className="p-4 border-b border-[#dddddd]">
              <h2 className="text-[#333] text-xl md:text-2xl font-semibold capitalize font-exo">
                Sports Information Section
              </h2>
            </div>
            <div className="px-4 py-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                    Student's Hobbies<span className="text-red-700">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Student's Hobbies"
                    className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                  />
                </div>
                <div>
                  <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                    Sports of Interest<span className="text-red-700">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Sports of Interest"
                    className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                  />
                </div>
              </div>
            </div>
          </div>
        )
      case 6:
        return (
          <div>
            <div className="p-4 border-b border-[#dddddd]">
              <h2 className="text-[#333] text-xl md:text-2xl font-semibold capitalize font-exo">
                Transcript Information Section
              </h2>
            </div>
            <div className="px-7 py-8">
              <div className="grid grid-cols-1 gap-7">
                <div className="flex flex-col ">
                  <div className="-mx-1.5 overflow-x-auto">
                    <div className="px-1.5 min-w-full inline-block align-middle">
                      <div className="overflow-hidden">
                        <table className="min-w-full">
                          <thead className="border-t border-[#000000de]">
                            <tr>
                              <th className="border-x border-[#000000de] py-4 px-3 text-lg font-medium text-dark">
                                File Name
                              </th>
                              <th className="border-x border-[#000000de] py-4 px-3 text-lg font-medium text-dark ">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                Passport Size Photograph
                                <span className="text-red-700">*</span>
                              </td>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                Student Passport
                                <span className="text-red-700">*</span>
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                G.C.E. Marksheet (Ordinary Level)
                                <span className="text-red-700">*</span>
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                12 Year High School (Academic Graduate Diploma)
                                Marksheet<span className="text-red-700">*</span>
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                XIth Grade Marksheet
                                <span className="text-red-700">*</span>
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                School Passing Certificate
                                <span className="text-red-700">*</span>
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                Birth Certificate
                                <span className="text-red-700">*</span>
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                Migration Certificate
                                <span className="text-red-700">*</span>
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                Migration Certificate
                                <span className="text-red-700">*</span>
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                Gap Certificate
                                <span className="text-red-700">*</span>
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                First Year Marksheet
                                <span className="text-red-700">*</span>
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                Second Year Marksheet
                                <span className="text-red-700">*</span>
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                Convocation
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                Passing Certificate
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                Sponsorship Affidavit
                                <span className="text-red-700">*</span>
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-nowrap">
                                Button for Upload More Transcript
                                <span className="text-red-700">*</span>
                              </td>

                              <td className="text-[#000000de] border border-[#000000de] py-4 px-3 text-base font-medium text-center">
                                <button
                                  className="bg-[#005cbb] border border-[#005cbb] rounded-lg inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white"
                                  type="button"
                                >
                                  <i className="fas fa-plus mr-3"></i>Upload
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl md:text-3xl text-[#131313] font-semibold uppercase font-exo">
          Your Profile Completeness
        </h1>
      </div>
      <div className="flex gap-7">
        {/* Left side steps */}
        <div className="flex-none">
          <div className="w-80">
            <div className="flex flex-col gap-4">
              {steps.map(step => (
                <button
                  key={step.id}
                  className={`p-5 w-full text-black text-lg text-left rounded-lg border ${
                    currentStep === step.id || completedSteps.includes(step.id)
                      ? "bg-[#005cbb] text-white"
                      : "bg-white text-[#333]"
                  }`}
                  disabled // Disable direct clicking
                >
                  {step.label}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="w-full bg-white rounded-lg">
            {renderStepContent()}

            {/* Navigation Buttons */}
            <div className="p-4 border-t border-[#dddddd] flex gap-4 justify-end">
              {currentStep > 1 && (
                <button
                  className="border border-[#005cbb] rounded-lg py-3 px-7 text-[#005cbb] hover:bg-[#146ac1] hover:border-[#146ac1] hover:text-white"
                  onClick={prevStep}
                  type="button"
                >
                  Back
                </button>
              )}
              {currentStep < 6 ? (
                <button
                  className="border border-[#005cbb] rounded-lg py-3 px-7 text-[#005cbb] hover:bg-[#146ac1] hover:border-[#146ac1] hover:text-white"
                  onClick={nextStep}
                  type="button"
                >
                  Next
                </button>
              ) : (
                <button
                  className="border border-[#005cbb] rounded-lg py-3 px-7 text-white bg-[#005cbb]"
                  type="button"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Profile)
