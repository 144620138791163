import React, { useEffect } from "react"
import { Navigate, useNavigate } from "react-router-dom"

const Authmiddleware = props => {
  const navigate = useNavigate()

  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
