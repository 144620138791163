import React from "react"
import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHouse, faBook, faMagnifyingGlass, faDownload, faCircleQuestion, faLifeRing, faAdjust } from "@fortawesome/free-solid-svg-icons"
const Sidebar = ({ sidebarToggle, setSidebarToggle }) => {
  const handleNavLinkClick = () => {
    setSidebarToggle(true)
  }
  const sidebarArray = [
    {
      name: "Dashboard",
      icon: faHouse,
      link: "/",
    },
    {
      name: "Profile",
      icon: faBook,
      link: "/profile",
    },
    {
      name: "Search College/Course",
      icon: faMagnifyingGlass,
      link: "/",
    },
    {
      name: "Downloads",
      icon: faDownload,
      link: "/",
    },
    {
      name: "FAQ",
      icon: faCircleQuestion,
      link: "/",
    },
    {
      name: "Help",
      icon: faLifeRing,
      link: "/",
    },
  ]

  return (
    <div className="relative">
      <aside
        className={`left-0 top-[96px] z-10 overflow-y-auto h-full min-h-screen flex-col xl:block hidden bg-white
             ${sidebarToggle ? "translate-x-0 fixed w-[252px]" : "translate-x-0 top-[96px] relative w-[80px]"}`}
      >
        <div className="flex flex-col overflow-y-auto duration-300 ease-linear">
          <nav className="pt-[10px] pb-[40px] mb-[50px] px-[20px] mt-4">
            <div>
              <ul className="mb-6 flex flex-col gap-1.5">
                {sidebarArray.map((item, index) => (
                  <li className="mb-[10px]" key={index}>
                    <NavLink
                      to={item.link}
                      className={({ isActive }) =>
                        `flex items-center rounded-md ${isActive ? "bg-[#f0f3fb]" : "bg-transparent"} ${
                          sidebarToggle
                            ? "w-[212px] h-[34px] p-[10px] justify-start"
                            : "w-[40px] h-[40px] p-0 justify-center"
                        }`
                      }
                    >
                      <FontAwesomeIcon icon={item.icon} />
                      <div
                        className={`text-[#333] text-[16px] font-normal font-body ml-2 ${sidebarToggle ? "block" : "hidden"}`}
                      >
                        {item.name}
                      </div>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </div>
      </aside>

      <aside
        className={`absolute left-0 top-[96px] z-10 overflow-y-auto h-full xl:hidden bg-white block flex-col  
             ${sidebarToggle ? "translate-x-0  w-[0px]" : "translate-x-0 w-[252px]"}`}
      >
        <div className="flex flex-col overflow-y-auto duration-300 ease-linear">
          <nav className="py-[10px] px-[20px] mt-4">
            <div>
              <ul className="mb-6 flex flex-col gap-1.5">
                {sidebarArray.map((item, index) => (
                  <li className="mb-[10px]" key={index}>
                    <NavLink
                      to={item.link}
                      className={({ isActive }) =>
                        `flex items-center rounded-md ${isActive ? "bg-[#f0f3fb]" : "bg-transparent"} w-[212px] h-[34px] p-[10px] justify-start`
                      }
                      onClick={handleNavLinkClick}
                    >
                      <FontAwesomeIcon icon={item.icon} />
                      <div
                        className={`text-[#333] text-[16px] font-normal font-body ml-2`}
                      >
                        {item.name}
                      </div>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </div>
      </aside>
    </div>
  )
}

export default Sidebar
