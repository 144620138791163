import React, { useState } from "react"
import withRouter from "../../components/Common/withRouter"
import { Link } from "react-router-dom"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import { Autoplay, Pagination } from "swiper/modules"
import img from "../../assets/images/admissionDeskLogo.jpg"
import user from "../../assets/images/man-in-blue-shirt-pencil.png"
import user1 from "../../assets/images/businesswoman.png"
import user2 from "../../assets/images/gender.png"
import img1 from "../../assets/images/Foreign-national.jpg"
import img2 from "../../assets/images/pio.jpg"
import img3 from "../../assets/images/nri.jpg"
import img4 from "../../assets/images/nri-south-east.jpg"
import img5 from "../../assets/images/other-nri.jpg"
import slide1 from "../../assets/images/slider1.png"
import slide2 from "../../assets/images/slider2.png"
import slide3 from "../../assets/images/slider3.png"
import logo from "../../assets/images/logo.png"
import logo2 from "../../assets/images/vidyapeeth_new.png"

const Registration = props => {
  const [value, setValue] = useState()
  const [startDate, setStartDate] = useState(new Date())
  const [currentStep, setCurrentStep] = useState(1)
  const [selectedCategory, setSelectedCategory] = useState(null)

  const nextStep = () => {
    setCurrentStep(prevStep => Math.min(prevStep + 1, 4))
  }

  const prevStep = () => {
    setCurrentStep(prevStep => Math.max(prevStep - 1, 1))
  }

  const handleClick = category => {
    setSelectedCategory(category)
  }

  const [selectedGender, setSelectedGender] = useState(null)

  const handleCheckboxChange = gender => {
    setSelectedGender(gender)
  }

  const [isCheckedBox, setIsCheckedBox] = useState(false)

  const handleBoxChange = () => {
    setIsCheckedBox(!isCheckedBox)
  }

  const [showPassword, setShowPassword] = useState(false)
  const [showRePassword, setShowRePassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const toggleRePasswordVisibility = () => {
    setShowRePassword(!showRePassword)
  }

  const [captchaCode, setCaptchaCode] = useState("")
  const [inputValue, setInputValue] = useState("")

  // Function to generate a new CAPTCHA code
  const generateCaptcha = () => {
    const code = Math.random().toString(36).substr(2, 5).toUpperCase()
    setCaptchaCode(code)
  }

  // Function to handle form submission
  const handleSubmit = () => {
    if (inputValue === captchaCode) {
      alert("CAPTCHA verified!")
    } else {
      alert("Incorrect CAPTCHA code. Please try again.")
    }
  }

  // Generate a new CAPTCHA code when the component mounts
  React.useEffect(() => {
    generateCaptcha()
  }, [])

  const stepNames = [
    "Current Location",
    "Additional Information",
    "Guardian Information",
    "Educational Information",
    "Sports Information",
    "Transcript Information",
  ]

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <h3 className="text-xl text-[#2a2a2a] font-medium font-exo text-center mb-4">
              Dear Student,
            </h3>
            <p className="text-lg text-[#2a2a2a] font-medium font-exo max-w-[436px] m-auto text-center">
              How would you like your name to be printed on your marksheet /
              application form.(Do not use nicknames)
            </p>
            <div className="mb-4 mt-6">
              <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                First Name<span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                placeholder="First Name"
                className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
              />
            </div>
            <div className="mb-4">
              <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                Last Name<span className="text-red-700">*</span>
              </label>
              <input
                type="email"
                placeholder="Last Name"
                className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
              />
            </div>
          </div>
        )
      case 2:
        return (
          <div>
            <h3 className="text-xl text-[#2a2a2a] font-medium font-exo text-center mb-4">
              Dear Student,
            </h3>
            <p className="text-lg text-[#2a2a2a] font-medium font-exo max-w-[436px] m-auto text-center">
              How would you like your name to be printed on your marksheet /
              application form.(Do not use nicknames)
            </p>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-8 mt-8 mb-8">
              <div
                className="text-center"
                onClick={() => handleCheckboxChange("male")}
              >
                <i
                  className={`fa-solid fa-person text-5xl ${selectedGender === "male" ? "text-[#005cbb]" : "text-[#4b4b4b]"}`}
                ></i>
                <label className="flex items-center justify-center cursor-pointer select-none text-[#4b4b4b] mt-2">
                  <div className="relative">
                    <input
                      type="checkbox"
                      checked={selectedGender === "male"}
                      onChange={() => handleCheckboxChange("male")}
                      className="sr-only"
                    />
                    <div
                      className={`box mr-2 flex h-5 w-5 items-center justify-center rounded-full ${selectedGender === "male" ? "border border-[#005cbb]" : "border border-[#2d5a6f]"}`}
                    >
                      <span
                        className={`h-[10px] w-[10px] rounded-full ${selectedGender === "male" ? "bg-[#005cbb]" : "bg-transparent"}`}
                      ></span>
                    </div>
                  </div>
                  Male
                </label>
              </div>

              <div
                className="text-center"
                onClick={() => handleCheckboxChange("female")}
              >
                <i
                  className={`fa-solid fa-person-dress text-5xl ${selectedGender === "female" ? "text-[#005cbb]" : "text-[#4b4b4b]"}`}
                ></i>
                <label className="flex items-center justify-center cursor-pointer select-none text-[#4b4b4b] mt-2">
                  <div className="relative">
                    <input
                      type="checkbox"
                      checked={selectedGender === "female"}
                      onChange={() => handleCheckboxChange("female")}
                      className="sr-only"
                    />
                    <div
                      className={`box mr-2 flex h-5 w-5 items-center justify-center rounded-full ${selectedGender === "female" ? "border border-[#005cbb]" : "border border-[#2d5a6f]"}`}
                    >
                      <span
                        className={`h-[10px] w-[10px] rounded-full ${selectedGender === "female" ? "bg-[#005cbb]" : "bg-transparent"}`}
                      ></span>
                    </div>
                  </div>
                  Female
                </label>
              </div>

              <div
                className="text-center"
                onClick={() => handleCheckboxChange("other")}
              >
                <i
                  className={`fa-solid fa-venus-mars text-5xl ${selectedGender === "other" ? "text-[#005cbb]" : "text-[#4b4b4b]"}`}
                ></i>
                <label className="flex items-center justify-center cursor-pointer select-none text-[#4b4b4b] mt-2">
                  <div className="relative">
                    <input
                      type="checkbox"
                      checked={selectedGender === "other"}
                      onChange={() => handleCheckboxChange("other")}
                      className="sr-only"
                    />
                    <div
                      className={`box mr-2 flex h-5 w-5 items-center justify-center rounded-full ${selectedGender === "other" ? "border border-[#005cbb]" : "border border-[#2d5a6f]"}`}
                    >
                      <span
                        className={`h-[10px] w-[10px] rounded-full ${selectedGender === "other" ? "bg-[#005cbb]" : "bg-transparent"}`}
                      ></span>
                    </div>
                  </div>
                  Other
                </label>
              </div>
            </div>
          </div>
        )
      case 3:
        return (
          <div>
            <h3 className="text-xl text-[#2a2a2a] font-medium font-exo text-center mb-4">
              Hi Mr. Rahul,
            </h3>
            <p className="text-lg text-[#2a2a2a] font-medium font-exo text-center">
              Choose your relevant student category.
            </p>
            <div className="grid grid-cols-1 gap-6 mt-8">
              {[
                "Foreign National",
                "PIO/OCI",
                "NRI from Gulf Countries",
                "SAARC NATIONALS",
                "Others",
              ].map(category => (
                <div
                  className="relative group"
                  key={category}
                  onClick={() => handleClick(category)}
                >
                  <div
                    className={`text-center border group-hover:border-[#005cbb] ${selectedCategory === category ? "border-[#005cbb]" : "border-dark"} 
                        rounded-md cursor-pointer ease-in-out duration-700 p-3`}
                  >
                    <div className="flex items-center gap-3">
                      {selectedCategory === category && (
                        <div className="bg-[#005cbb] text-white h-[26px] w-[26px] flex items-center justify-center rounded-full p-2">
                          <i className="fas fa-check text-base"></i>
                        </div>
                      )}
                      <p className="text-[#4b4b4b]">{category}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      case 4:
        return (
          <div>
            <h3 className="text-xl text-[#2a2a2a] font-medium font-exo text-center mb-4">
              Hi Mr. rahul,
            </h3>
            <p className="text-lg text-[#2a2a2a] font-medium font-exo text-center">
              Let's set up your login credentials.
            </p>
            <div className="mb-4 mt-6">
              <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                Student's Email ID<span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                placeholder="Email"
                className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
              />
            </div>
            <div className="mb-4">
              <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                Password<span className="text-red-700">*</span>
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <i className="fa-solid fa-eye-slash"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="mb-[10px] block text-base font-medium text-[#4b4b4b]">
                Confirm Password<span className="text-red-700">*</span>
              </label>
              <div className="relative">
                <input
                  type={showRePassword ? "text" : "password"}
                  placeholder="Password"
                  className="w-full bg-transparent rounded-md border border-[#777779] py-[10px] px-5 text-black outline-none transition focus:border-[#005cbb] active:border-[#005cbb] disabled:cursor-default placeholder:text-[#333]"
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={toggleRePasswordVisibility}
                >
                  {showRePassword ? (
                    <i className="fa-solid fa-eye-slash"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-4 custom-phone-bg">
              <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                Country Code and Mobile number{" "}
                <span className="text-red-700">*</span>
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="IN"
                country="US"
                value={value}
                onChange={setValue}
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="checkboxLabelTwo"
                className="flex items-center text-xl font-exo font-meduim cursor-pointer select-none text-dark "
              >
                <div className="relative">
                  <input
                    type="checkbox"
                    id="checkboxLabelTwo"
                    className="sr-only"
                    checked={isCheckedBox}
                    onChange={handleBoxChange}
                  />
                  <div
                    className={`flex items-center justify-center w-5 h-5 mr-4 border border-[#005cbb] dark:border-dark-3 rounded box ${
                      isCheckedBox ? "bg-blue-500" : "bg-white"
                    }`}
                  >
                    {isCheckedBox && (
                      <span>
                        <svg
                          width="11"
                          height="8"
                          viewBox="0 0 11 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                            fill="#fff"
                            stroke="#fff"
                            strokeWidth="0.4"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                </div>
                Same as WhatsApp No?
              </label>
            </div>
            <div className="mb-6 mt-4">
              <label className="mb-[10px] block text-base font-medium text-[#4b4b4b] ">
                Date of Birth Format Should be like (dd/mm/yyyy)only
                <span className="text-red-700">*</span>
              </label>
              <DatePicker
                showIcon
                selected={startDate}
                onChange={date => setStartDate(date)}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 48 48"
                  >
                    <mask id="ipSApplication0">
                      <g
                        fill="none"
                        stroke="#fff"
                        strokeLinejoin="round"
                        strokeWidth="4"
                      >
                        <path
                          strokeLinecap="round"
                          d="M40.04 22v20h-32V22"
                        ></path>
                        <path
                          fill="#fff"
                          d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                        ></path>
                      </g>
                    </mask>
                    <path
                      fill="currentColor"
                      d="M0 0h48v48H0z"
                      mask="url(#ipSApplication0)"
                    ></path>
                  </svg>
                }
              />
            </div>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <>
      <div className="container-fluid m-auto">
        <div className="bg-white">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="hidden md:block">
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="relative">
                    <img
                      src={slide1}
                      alt="img"
                      className="w-full h-[960px] object-cover"
                    />
                    <div className="absolute left-0 top-0 p-12">
                      <h2 className="text-3xl font-exo font-semibold max-w-[755px]">
                        Apply to more than 500+ courses at the university
                      </h2>
                      <p className="text-base font-medium mt-2 max-w-[755px]">
                        Study on campus or any of 750+ affiliate colleges of
                        university.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="relative">
                    <img
                      src={slide2}
                      alt="img"
                      className="w-full h-[960px] object-cover"
                    />
                    <div className="absolute left-0 top-0 p-12">
                      <h2 className="text-3xl font-exo font-semibold max-w-[755px]">
                        Study at one of the oldest universities in India
                      </h2>
                      <p className="text-base font-medium mt-2 max-w-[755px]">
                        University with 5 star status in 2001 & 'A' Grade status
                        in 2012.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="relative">
                    <img
                      src={slide3}
                      alt="img"
                      className="w-full h-[960px] object-cover"
                    />
                    <div className="absolute left-0 top-0 p-12">
                      <h2 className="text-3xl font-exo font-semibold max-w-[755px]">
                        Study with students from more than 35+ countries
                      </h2>
                      <p className="text-base font-medium mt-2 max-w-[755px]">
                        Study with students from more than 35+ countries,
                        fostering a diverse and enriching learning environment
                        that broadens your perspectives.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="bg-white flex items-center justify-center px-6 md:px-20 py-20 min-h-screen md:h-auto">
              <div className="max-w-[690px] m-auto">
                <form action="#" method="post">
                  <div data-stepper="true">
                    <div className="card">
                      <div className="card-header  items-center gap-4 hidden">
                        {stepNames.map((name, index) => {
                          const step = index + 1
                          return (
                            <div
                              key={step}
                              className={`flex gap-2.5 items-center w-full ${
                                currentStep === step ? "active" : ""
                              }`}
                              data-stepper-item={`#stepper_${step}`}
                            >
                              <div className="flex-none">
                                <div
                                  className={`rounded-full size-7 flex items-center justify-center text-base font-medium ${
                                    currentStep === step
                                      ? "bg-[#3d50ae] text-white"
                                      : currentStep > step
                                        ? "bg-[#3d50ae] text-white"
                                        : "bg-[#00000061] text-white"
                                  }`}
                                >
                                  <span
                                    className={`${currentStep > step ? "hidden" : ""}`}
                                    data-stepper-number="true"
                                  >
                                    {step}
                                  </span>
                                  <i
                                    className={`fa-solid fa-pen text-base ${
                                      currentStep > step ? "block" : "hidden"
                                    }`}
                                  ></i>
                                </div>
                              </div>
                              <div className="flex-1">
                                <div className="flex items-center w-full gap-0.5">
                                  <h4
                                    className={`text-sm font-medium text-gray-900 flex-none ${
                                      currentStep > step ? "text-gray-600" : ""
                                    }`}
                                  >
                                    {name}
                                  </h4>
                                  {step !== stepNames.length && (
                                    <p className="bg-[#acacac] h-[1px] w-full flex-1 ml-2"></p>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div className="card-body">{renderStepContent()}</div>
                      <div className="card-footer py-8 flex justify-between">
                        <div>
                          {currentStep > 1 && (
                            <button
                              className="border border-[#005cbb] rounded-lg py-3 px-7 text-[#005cbb] hover:bg-[#146ac1] hover:border-[#146ac1] hover:text-white"
                              onClick={prevStep}
                              type="button"
                            >
                              Previous
                            </button>
                          )}
                        </div>
                        <div>
                          {currentStep < 4 ? (
                            <button
                              className="border border-[#005cbb] rounded-lg py-3 px-7 text-[#005cbb] hover:bg-[#146ac1] hover:border-[#146ac1] hover:text-white"
                              onClick={nextStep}
                              type="button"
                            >
                              Next
                            </button>
                          ) : (
                            <Link
                              to="/profile"
                              className="border border-[#005cbb] rounded-lg py-3 px-7 bg-[#146ac1]  text-white block"
                              type="button"
                            >
                              Proceed
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Registration)
